// import dotenv from "dotenv";
// dotenv.config();
import Vue from "vue";
import App from "./App.vue";
// import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";

Vue.config.productionTip = true;
// Vue.use(BootstrapVue);
 
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
