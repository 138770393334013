
import { Vue, Component, Watch } from "vue-property-decorator";
import abi from "../abi";
import Web3 from "web3";
import Web3Utils from "web3-utils";
import axios from "axios";
import { BModal, VBModal, BCardGroup, BInputGroup, BFormInput, BCard, BButton } from 'bootstrap-vue'
const web33 = new Web3();
const web3Utils = web33.utils;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pureknob = require("../library/pureknob");
const knob = pureknob.createKnob(300, 300);
interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ethereum: any;
  location: any
}
interface indexData {
  address: string;
  price: string;
  message: string;
  balance?: string;
  received: string;
  disabled: boolean;
  buttonText: string;
}

interface localData {
  hash: string;
  index: number;
}

declare const window: Window;

@Component({
  name: "Home", components: {
    'b-modal': BModal,
    'b-card-group': BCardGroup,
    'b-input-group': BInputGroup,
    'b-form-input': BFormInput,
    'b-card': BCard,
    'b-button': BButton
  },
  directives: {
    'b-modal': VBModal,
  }
})
export default class Home extends Vue {
  indexs: indexData[] = [];
  web3!: Web3;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
  myContract!: any;
  ethereum!: any;
  addressModal = false;
  addressData: indexData | "" = "";
  amountToDonate = 0;
  amountToDonateInDolar: number | string = 0;
  realAmountToDonate: number | string = 0;
  addOrEditMessageModal = false;
  readonly = true;
  selectedIndex = -1;
  usdData = 0;
  pendingTransaction: localData[] = [];
  selectedAddress = ""
  chainIdList = {
    1: { name: "Ethereum Mainnet", contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS },
    10: { name: "Optimism Mainnet", contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS_OPTIMISM_MAINNET },
    420: { name: "Optimism Goerli Testnet", contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS_OPTIMISM_TESTNET }
  }

  chainId: 1 | 10 | 420 = 1
  networkList = {
    420: {
      chainId: web3Utils.toHex(420), chainName: this.chainIdList[420].name, nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://goerli.optimism.io"],
      blockExplorerUrls: ["https://goerli-explorer.optimism.io"],
      // chainName: "Optimism Goerli Testnet"
    },
    10: {
      chainId: web3Utils.toHex(10), chainName: this.chainIdList[10].name, nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.optimism.io"],
      blockExplorerUrls: ["https://explorer.optimism.io"],
      // chainName: "Optimism Mainnet"
    }
  }

  @Watch("amountToDonate")
  watchAmountToDonate(value: number): void {
    // console.log("value", value);
    const val = this.web3.utils.toWei(value.toString(), "ether");
    // knob.setValue(val);
    this.realAmountToDonate = Number(
      this.web3.utils.fromWei(val.toString(), "ether")
    );
  }

  @Watch("amountToDonateInDolar")
  watchAmountToDonateInDolar(value: number): void {
    // console.log("value", value);
    const valueInEther = value / this.usdData;
    // knob.setValue(this.web3.utils.toWei(valueInEther.toString(), "ether"));
    this.amountToDonate = valueInEther;
  }

  @Watch("realAmountToDonate")
  watchrealAmountToDonate(value: number): void {
    // console.log("value", value);
    knob.setValue(this.web3.utils.toWei(value.toString(), "ether"));
    // this.realAmountToDonate = Number(
    //   this.web3.utils.fromWei(value.toString(), "ether")
    // );
  }

  @Watch("pendingTransaction", { deep: true })
  watchLocalData(value: localData[]): void {
    localStorage.pendingTransaction = JSON.stringify(value);
  }

  async changeNetwork(chainId: 10 | 420): Promise<void> {
    try {
      await this.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [this.networkList[chainId]],
      })

    } catch (e) {
      //
    }

  }

  async switchNetworkToEthereum(chainId: number): Promise<void> {
    await this.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{
        chainId: this.web3.utils.toHex(chainId)

      }],
    })

  }

  async mounted(): Promise<void> {
    // console.log("pureknob", pureknob);

    if (localStorage.pendingTransaction) {
      this.pendingTransaction = JSON.parse(localStorage.pendingTransaction);
    }

    for (let i = 0; i <= 98; i++) {
      this.indexs.push({
        address: "Loading...",
        price: "Loading...",
        received: "Loading...",
        message: "",
        disabled: true,
        buttonText: "Loading..."
      });
    }
    const usdData = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
    );
    this.usdData = usdData.data.USD;
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");
      this.ethereum = window.ethereum;
      this.ethereum.on('chainChanged', (chainId: number) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        window.location.reload();
      });
      await this.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.web3 = new Web3(this.ethereum);
      this.chainId = await this.web3.eth.getChainId() as 1 | 10 | 420
      console.log(" this.chainId", this.chainId)
      const address = await this.web3.eth.getAccounts();
      this.selectedAddress = address[0];
      this.myContract = new this.web3.eth.Contract(abi, this.chainIdList[this.chainId].contractAddress);
      // const pendingTransaction = await this.web3.eth.getPendingTransactions();
      await this.showMessages()

    } else {
      alert("MetaMask is not installed!");
    }
    // console.log("ethereum.isConnected()", ethereum.isConnected())
  }

  async showMessages(): Promise<void> {
    for (let i = 0; i <= 98; i++) {
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {


        let buttonText = "";

        let address = await this.myContract.methods.getOwner(i).call({
          from: this.selectedAddress,
        });
        if (address === "0x0000000000000000000000000000000000000000") {
          address = "";
        }
        let disabled = false;
        // if (address) {
        const checkIfPending = this.pendingTransaction.find(
          (trx: { index: number; hash: string }) => trx.index === i
        );
        if (checkIfPending) {
          disabled = true;
          buttonText = "Pending transaction ...";
          this.checkIfStillPending(checkIfPending.hash, i);
        }
        // }

        let price = await this.myContract.methods.getPrice(i).call({
          from: this.selectedAddress,
        });
        price = this.web3.utils.fromWei(price.toString(), "ether");
        if (price > 0) {
          const endPrice = Number(price).toFixed(5);
          price = `Price is ${endPrice} ETH | $${(
            Number(endPrice) * this.usdData
          ).toFixed(2)}`;
        } else {
          price = "price is 0.00000 ETH | $0.00";
        }

        let received = await this.myContract.methods.getDonated(i).call({
          from: this.selectedAddress,
        });
        if (received > 0) {
          const endReceived = Number(
            this.web3.utils.fromWei(received, "ether")
          ).toFixed(5);
          received = `Received ${endReceived} ETH | $${(
            Number(endReceived) * this.usdData
          ).toFixed(2)}`;
        } else {

          received = "Received 0 ETH | $0";

        }

        const balance = await this.web3.eth.getBalance(
          this.selectedAddress
        );

        const message = await this.myContract.methods.getText(i).call({
          from: this.selectedAddress,
        });
        if (!buttonText) {
          if (message) {
            buttonText = "Own and Edit this Space";
            if (
              address.toLowerCase() === this.selectedAddress.toLowerCase()
            ) {
              buttonText = "Edit Your Space";
            }
          } else {
            buttonText = "Create My Own Message";
          }
        }

        this.indexs.splice(i, 1, {
          address,
          price,
          message,
          balance,
          received,
          disabled,
          buttonText,
        });
        resolve(true)
      });
      await sleep(100)
    }
  }

  async showAddressModal(id: number): Promise<void> {
    this.addressModal = true;

    this.addressData = this.indexs[id];

    // Set properties.
    knob.setProperty("angleStart", -0.75 * Math.PI);
    knob.setProperty("angleEnd", 0.75 * Math.PI);
    knob.setProperty("colorFG", "#000099");
    knob.setProperty("colorBG", "#000000");
    knob.setProperty("trackWidth", 0.3);
    knob.setProperty("valMin", 0);
    knob.setProperty("valMax", Number(this.addressData.balance));

    // knob.textScale()
    // Set initial value.
    knob.setProperty("textScale", 0.2);
    knob.setProperty("fnValueToString", (value: number) => {
      const valueInEth = this.web3.utils.fromWei(value.toString(), "ether");
      this.amountToDonateInDolar = (Number(valueInEth) * this.usdData).toFixed(
        2
      );
      const data =
        Number(valueInEth).toFixed(5) + ` ETH / $${this.amountToDonateInDolar}`;
      // console.log("data", data);
      return data;
    });
    this.amountToDonate = Number(this.addressData.balance) / 2;
    knob.setValue(this.amountToDonate);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (knob: any, value: number) => {
      this.realAmountToDonate = Number(
        this.web3.utils.fromWei(value.toString(), "ether")
      ).toFixed(5);
    };

    knob.addListener(listener);

    // Create element node.
    const node = knob.node();

    // Add it to the DOM.
    await sleep(100);
    const elem = document.getElementById(`knob-element`);
    elem?.appendChild(node);
  }

  async sendDonation(): Promise<void> {
    if (this.realAmountToDonate > 0 && this.addressData) {
      const index = this.indexs.findIndex((i) => {
        if (this.addressData) {
          return i.address === this.addressData.address;
        }
      });
      if (index >= 0) {
        await this.myContract.methods
          .donate(this.addressData.address, Number(index))
          .send({
            from: this.selectedAddress,
            value: this.web3.utils.toWei(
              this.realAmountToDonate.toString(),
              "ether"
            ),
          })
          .on("transactionHash", (hash: string) => {
            alert("donation sent");
            this.pendingTransaction.push({
              index,
              hash,
            });
            this.addressModal = false;
            location.reload();
          });
      } else {
        alert("index for this address not found!");
      }
    } else {
      alert("amount must be greater than 0");
    }
  }

  async showAddOrEditMessageModal(index: number): Promise<void> {
    this.selectedIndex = index;
    this.addressData = this.indexs[this.selectedIndex];
    this.readonly = this.addressData.message ? true : false;
    this.addOrEditMessageModal = true;
  }

  async updateOrAddMessage(): Promise<void> {
    if (this.readonly === true) {
      this.readonly = false;
    } else {
      if (this.addressData && this.readonly === false) {
        const price = await this.myContract.methods
          .getPrice(this.selectedIndex)
          .call({
            from: this.selectedAddress,
          });
        const amount = (price + 1).toString();
        // console.log(Number(this.selectedIndex), this.addressData.message);
        this.readonly = false;
        this.myContract.methods
          .setMessage(Number(this.selectedIndex), this.addressData.message) //function in contract
          .send({
            from: this.selectedAddress,
            to: this.contractAddress,
            value: amount,
            // gasPrice: this.web3.utils.toWei("5", "gwei"),
          })
          .on("transactionHash", (hash: string) => {
            this.pendingTransaction.push({
              index: this.selectedIndex,
              hash,
            });
            location.reload();
          });
      }
    }
  }

  async checkIfStillPending(hash: string, index: number): Promise<void> {
    const trx = await this.web3.eth.getTransactionReceipt(hash);
    if (trx) {
      const pendingIndex = this.pendingTransaction.findIndex(
        (trx: { index: number; hash: string }) => trx.index === index
      );
      const message = await this.myContract.methods.getText(index).call({
        from: this.selectedAddress,
      });
      let address = await this.myContract.methods.getOwner(index).call({
        from: this.selectedAddress,
      });
      if (address === "0x0000000000000000000000000000000000000000") {
        address = "";
      }
      let buttonText = "Edit Your Space";

      this.pendingTransaction.splice(pendingIndex, 1);
      this.indexs.splice(
        index,
        1,
        Object.assign(this.indexs[index], {
          disabled: false,
          message,
          buttonText,
          address
        })
      );
    } else {
      await sleep(10000);
      await this.checkIfStillPending(hash, index);
    }
  }
}
function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// function htmlEncode(str: string): string {
//   // eslint-disable-next-line no-useless-escape
//   return String(str).replace(/[<>=&+'%"(){}[\]:,\.;]/g, (match) => {
//     switch (match) {
//       case '<':
//         return '&lt;';
//       case '>':
//         return '&gt;';
//       case '=':
//         return '&#61;';
//       case '&':
//         return '&amp;';
//       case '+':
//         return '&#43;';
//       case '\'':
//         return '&#39;';
//       case '"':
//         return '&quot;';
//       case '%':
//         return '&#37;';
//       case '(':
//         return '&#40;';
//       case ')':
//         return '&#41;';
//       case '{':
//         return '&#123;';
//       case '}':
//         return '&#125;';
//       case '[':
//         return '&#91;';
//       case ']':
//         return '&#93;';
//       case ':':
//         return '&#58;';
//       case ',':
//         return '&#44;';
//       case '.':
//         return '&#46;';
//       case ';':
//         return '&#59;';
//     }
//   });
// }

