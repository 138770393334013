
import { Vue, Component } from "vue-property-decorator";
import Web3 from "web3";
import abi from "../abi";
import { BModal, VBModal, BCardGroup, BInputGroup, BFormInput, BCard, BButton } from 'bootstrap-vue';

interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ethereum: any;
}
declare const window: Window;
interface indexData {
  message: string | null;
  price: number | null;
}
@Component({ name: "Admin", components: {
    'b-modal': BModal,
    'b-card-group' : BCardGroup,
    'b-input-group' : BInputGroup,
    'b-form-input' : BFormInput,
    'b-card' : BCard,
    'b-button' : BButton
  },
  directives: {
    'b-modal': VBModal,
  } })
export default class Admin extends Vue {
  web3!: Web3;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
  myContract!: any;
  ethereum!: any;
  increment: "" | number = "";
  indexs: indexData[] = [];
  async mounted(): Promise<void> {
    for (let i = 0; i <= 98; i++) {
      this.indexs.push({
        message: null,
        price: null,
      });
    }
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");

      this.ethereum = window.ethereum;
      await this.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.web3 = new Web3(this.ethereum);
      this.myContract = new this.web3.eth.Contract(abi, this.contractAddress);
      this.increment = await this.myContract.methods.getIncriment().call({
        from: (this.web3.currentProvider as any).selectedAddress,
      });
    } else {
      alert("MetaMask not is installed!");
    }
  }

  async setIncrement(): Promise<void> {
    await this.myContract.methods
      .setIncrement(Number(this.increment)) //function in contract
      .send({
        from: (this.web3.currentProvider as any).selectedAddress,
        to: this.contractAddress,
        value: "0",
        gasPrice: this.web3.utils.toWei("5", "gwei"),
      })
      .on("transactionHash", () => {
        location.reload();
      });
  }

  async setMessageAsOwner(index: number): Promise<void> {
    this.myContract.methods
      .setMessageAsOwner(Number(index), this.indexs[index].message) //function in contract
      .send({
        from: (this.web3.currentProvider as any).selectedAddress,
        to: this.contractAddress,
        value: "0",
        gasPrice: this.web3.utils.toWei("5", "gwei"),
      })
      .on("transactionHash", () => {
        location.reload();
      });
  }

  async setPrice(index: number): Promise<void> {
    this.myContract.methods
      .setPrice(Number(index), this.indexs[index].price) //function in contract
      .send({
        from: (this.web3.currentProvider as any).selectedAddress,
        to: this.contractAddress,
        value: "0",
        gasPrice: this.web3.utils.toWei("5", "gwei"),
      })
      .on("transactionHash", () => {
        location.reload();
      });
  }
}
