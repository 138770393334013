import Vue from "vue";
import VueRouter from "vue-router";
import { Home, Admin } from "../components";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: Home
    // beforeEnter: Authorize
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin
    // beforeEnter: Authorize 
  }
];

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(async (to: Route, from: Route, next: any) => {
//   // console.log("to", to);
// });

export default router;
